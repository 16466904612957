@import "pnp_core_bundle/../sass/base/_variables.scss";

$font-source-sans-pro: $font-main-one;
$font-lato: 'Lato', sans-serif;
$font-inter: 'Inter', sans-serif;
$font-style-normal: normal;
$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$letter-spacing-default: -0.333333px;

#ctp-favorites {
  .simplebar-wrapper {
    position: absolute;
  }

  .simplebar-offset {
    // right: 0 !important;
  }

  .simplebar-content-wrapper {
    width: 100% !important;
  }

  .simplebar-wrapper {
    width: 100% !important;
  }

  .simplebar-scrollbar::before {
    // background-color: deeppink;
  }
  .transport-tab-content, .journey-tab-content, .address-tab-content {
    font-family: $font-source-sans-pro;
    font-style: normal;
    // overflow: hidden;
    position: absolute;
    left: 0px;
    background: #F8F8F8;
    width: 100%;
    padding: 0px 40px 200px 40px;
    height: 100%;
    // overflow: none;


    .menu {
      margin-top: 0px;
      padding: 5px;
    }

    .journey-item {
      cursor: pointer;
      .marker-from, .marker-to {
        width: 12px;
        margin-right: 5px;
        position: relative;
        top: -2px;
      }
      .journey-from {
        float: left;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */

        letter-spacing: -0.333333px;

        width: 100%;
        // margin-bottom: 15px;
      }

      .journey-to {
        float: left;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */

        letter-spacing: -0.333333px;

        width: 100%;
        // margin-top: 15px;
      }

      .separator {
        margin: 0 5px;
      }

      .journey-display {
        width: 90%;
      }
      .journey-time {
        width: 13%;

        position: relative;
        // left: 10px;
        top: 22px;

        font-family: $font-inter;
        font-style: normal;
        font-weight: $font-weight-regular;
        font-size: 10px;
        line-height: 17px;
        text-align: center;
        letter-spacing: -0.333333px;

        span {
          font-size: 15px;
          color: #0EBDAB;
          //line-height: 1.3;
        }
      }

      .sections {
        display: flex;
        width: 100%;
      }


      .transport-section {
        display: flex;
      }

      .commercial-mode {
        display: flex;
        margin-right: 5px;
      }

      .commercial-number {
        display: flex;
        padding: 0 5px;
      }

    }

    h3 {
      i {
        margin-right: 4px;
        display: inline-block;
        position: relative;
        top: 2px;
      }
      margin: 11px 0 0 0;
      font-family: $font-source-sans-pro;
      font-style: $font-style-normal;
      font-weight: $font-weight-bold;
      font-size: 17px;
      line-height: 21px;
      letter-spacing: $letter-spacing-default;
      color: #000000;
    }

    .item {
      width: 100%;
      overflow: hidden;
      padding: 15px;
      background: #FFFFFF;
      border-radius: 8px;


      &:first-child {
        margin: 10px 0 0 0 !important;
      }

      & + .item {
        margin: 8px 0 0 0 !important;
      }

      &:hover {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
      }

      .header {
        position: relative;
        width: 100%;

        .commercial_mode,
        .line {
          background-color: white;
          border-radius: 3px;
          margin-right: 6px;
        }

        .line {
          padding: 0 5px;
        }

        .commercial_mode img {
          width: 15px;
        }

        .stop-name {
          margin-left: 4px;
          font-family: $font-source-sans-pro;
          font-style: $font-style-normal;
          font-weight: $font-weight-semi-bold;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: $letter-spacing-default;
          color: #000000;
          max-width: 330px;
        }

        span {
          display: inline-block;
        }

      }

      .content {
        width: 100%;
        padding: 10px 0 0 0;
      }

      .routes:first-child {
        height: 55px;
        margin-top: 10px;
      }


      .routes {

        &:first-child {
          background-color: yellow;
          height: 55px;
          margin-top: 10px;
        }

        width: 100%;
        overflow: hidden;
        margin-top: 5px;

        .direction {
          display: inline-block;
          float: left;
          font-family: $font-source-sans-pro;
          font-style: $font-style-normal;
          font-weight: $font-weight-regular;
          font-family: $font-source-sans-pro;
          font-style: $font-style-normal;
          font-size: 12px;
          line-height: 17px;
          letter-spacing: $letter-spacing-default;
          color: #000000;
          width: 60%;
        }

        .next {
          display: inline-block;
          float: right;
          font-family: $font-lato;
          font-style: $font-style-normal;
          font-weight: $font-weight-medium;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: $letter-spacing-default;
          color: #241C24;
          border: 1px solid #0EBDAB;
          border-radius: 16px;
          padding: 2px 4px;
          img {
            width: 8px;
            position: relative;
            top: -2px;
            margin-right: 2px;
          }
        }

        .not-avalaible-next {
          float: right;
          font-family: $font-lato;
          font-style: italic;
          font-weight: $font-weight-medium;
          font-size: 12px;
          line-height: 17px;
          letter-spacing: $letter-spacing-default;
          color: #5F5D5F;
        }
      }
    }

    .shared-item {
      display: flex;
      flex-direction: column;
      margin-top: 8px;
      width: 100%;
      padding: 15px;
      background: #FFFFFF;
      border-radius: 8px;

      &:hover {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
      }

      .header {
        display: block;
        align-items: center;
        position: relative;
        width: 100%;
        padding-bottom: 10px;

        .icon {
          // Ajoutez le style de l'icône ici
        }

        .icon_vls img {
          width: 24px;
        }

        .name {
          margin-left: 8px;
          font-family: $font-source-sans-pro;
          font-style: $font-style-normal;
          font-weight: $font-weight-semi-bold;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: $letter-spacing-default;
          color: #000000;
        }
      }

      .content {
        margin-top: 8px;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .address {
          flex: 0;
          margin-bottom: 4px;
          font-family: $font-inter;
          font-style: normal;
          font-weight: $font-weight-regular;
          font-size: 12px;
          line-height: 17px;
          display: flex;
          align-items: center;
          color: #000000;
          min-width: 130px;
        }

        .parent-slot {
          display: flex;
          flex: 1;
          flex-flow: row;
          max-width: fit-content;
        }

        .left-slot,
        .right-slot {
          // flex: 1;
          font-family: $font-source-sans-pro;
          font-style: $font-style-normal;
          font-weight: $font-weight-regular;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: $letter-spacing-default;
          color: #000000;
          background: #FFFFFF;
          border-radius: 8px;
          padding: 2px 4px;
          margin-bottom: 4px;
          height: 24px;
          max-width: fit-content;

          .icon {
            // Ajoutez le style de l'icône ici
            margin-right: 4px;
          }

          .section_icon {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            border: 1px solid #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 2px;
          }

          .ikisio-parking {
            font-size: 10px;
            padding: 0;
          }

          .ikisio-big-bicycle  {
            padding: 0;
            font-size: 8px;
          }

          &:first-child {
            margin-right: 8px;
          }

          .number {
            display: flex;
            align-items: center;
            margin-right: 4px;
            img {
              margin-right: 4px;
            }
            .bookmark_realtime_vls {
              width: 8px;
              position: relative;
              top: -3px;
            }
          }
        }

        .left-slot {
          border: 0.5px solid;
          border-radius: 50px;
          color: #FFFFFF;
          margin-right: 5px;
        }

        .right-slot {
          border: 0.5px solid;
          border-radius: 50px;
          color: #FFFFFF;
        }

        .orange {
          background-color: #E5680C;
          border-color: #E5680C;
        }

        .red {
          background-color: #DE190B;
          border-color: #DE190B;
        }

        .green {
          background-color: #0FA958;
          border-color: #0FA958;
        }

        .grey {
          background-color: rgba(37, 25, 66, 0.3);
          border-color: rgba(37, 25, 66, 0.3);
        }
      }

      .from-to-buttons-container button {
        margin-top: 15px;
      }

      .actions {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 8px;

        button {
          display: flex;
          align-items: center;
          width: 120px;
          height: 34px;
          float: left;
          margin: 0 10px;
          font-size: 14px;
          background-color: #FFFFFF;
          box-shadow: 0px 4px 4px -2px rgba(37, 25, 66, 0.5);
          border-radius: 50px;
          border: none;
          font-weight: 600;
          justify-content: center;
          color: #3c3c3c;

          span {
            font-family: $font-source-sans-pro;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;

          }

          &::before {
            transform: translateX(-5px);
          }

          &:hover {
            cursor: pointer;
            background-color: $color-main-one;
            color: #ffffff !important;
          }
        }
      }

      .address-title i {
        position: relative;
        top: 2px;
        margin-right: 5px;
      }
    }

    .vls-item .header:after, .parking-item .header:after   {
      content: " ";
      position: absolute;
      width: calc(100% + 15px);
      border: 1px solid #E5E5E5;
      bottom: 0;
      right: -15px;
    }

    /***************************************
    ** NEED THE FOR ALL
    ****************************************/
    .menu {
      cursor: pointer;
      position: relative;
      line-height: 5px;
    }

    .menu.active {
      z-index: 1200;
    }

    .no-header {
      float: right;
      position: absolute;
      right: 0;
    }

    .remove-favorite {
      &:focus-visible {
        outline: auto !important;
      }
      background: #FFFFFF;
      border-radius: 8px;
      padding: 8px;
      z-index: 1100;
      position: absolute;
      top: -10px;
      right: -15px;

      i {
        margin-right: 6px;
        width: 20px;
      }

      a:first-child {
        padding-top: 20px;
      }

      a {

        &:hover {
          color: #C10F0F;
        }
        color: #000000;
        margin-top: 5px;
        font-family: $font-source-sans-pro;
        font-style: $font-style-normal;
        font-weight: $font-weight-semi-bold;
        font-size: 16px;
        line-height: 25px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: $letter-spacing-default;

      }
    }

    .no-margin-top {
      margin-top: 0 !important;
    }

    .left {
      float: left;
    }

    .right {
      float: right;
    }
    .loader-content {
      width: 100%;
      float: left;
      padding: 20px;
    }
    .section-routes {
      padding-top: 15px;
    }


  }// /transport-tab-content

  /**
  * New address
   */
  .adress-new-container{
    h3 {
      font-size: 19px;
    }
  }

  .overlay-remove-favorite {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .no-bookmark-content {
    padding: 60px 0px;
    text-align: center;
  }

  .button {

    // content
    padding: 15px 25px;
    text-align: center;
    // design
    background: $color-main-one;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    color: white;
    // action
    cursor: pointer;
    &:hover {
      background: #ffffff;
      color: $color-main-one;
    }
  }

  .bottom {
    // position
    position: absolute;
    bottom: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1400;
  }

  .return-bookmark {
    &:focus-visible {
      outline: auto;
    }
    margin-bottom: 10px;
    a {
      font-size: 13px;
      border: none;
      color: #000000 !important;
      i {
        font-size: 14px;
        color: $color-main-one;
        margin-right: 5px;
        vertical-align: sub;
      }
    }
  }

  /************************
  ** CSS IMPORTE
  *************************/


  .sections, .item {
    justify-content: space-between;
    margin-top: 25px;

    .kisio-right-info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;

      .kisio-duration-info {
        margin-left: 10px;
        span {
          color: yellow;
          white-space: nowrap;
          font-weight: bold;
        }
      }

      .kisio-price-info {
        span {
          font-size: 13px;
          display: inline-block;
          float: right;
          color: #808080;
        }
      }
    }

    .kisio-left-info {
      display: flex;

      .kisio-duration-info {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .duration {
          font-weight: 600;
          color: yellow;
        }

        .total-distance {
          font-size: 12px;
          font-weight: 400;
          color: #808080;
        }
      }

      ul {
        display: flex;
        align-items: flex-end;
        padding: 0;
        margin-left: 10px;

        li {
          margin-top: 10px;
          list-style-type: none;
          display: -webkit-box;

          .duration {
            font-size: 10px;
            margin-left: 3px;
          }

        }

        &.mode-type {
          &__bike, &__bss {
            align-items: start;
            flex-direction: column;

            .percentage {
              position: relative;
              color: #76CC5B;
              font-style: italic;
              font-weight: 600;
              font-size: 12px;
            }

            .percentage-bar {
              min-width: 200px;
              height: 10px;
              display: flex;

              div:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
              }

              div:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
              }
            }
          }
        }

        li.kisio-division {
          background-color: #B7B7B7;;
          border-radius: 7px;
          height: 7px;
          width: 7px;
          margin: 8px 5px;
        }
      }// /ul
    }

    .kisio-line-code {
      text-transform: uppercase;
      padding: 0 5px;
      height: 24px;
      position: relative;
      display: inline-block;
      line-height: 2;
      min-width: 24px;
      text-align: center;
      border-radius: 3px;
      font-family: $font-source-sans-pro;
      font-weight: bold;
      font-size: 12px;
    }

    .kisio-img-line-code {
      height: 24px;
      position: relative;
      top: 1px;
    }

    .kisio-public-transport {
      position: relative;

      .ikisio-tad {
        position: inherit;
        border: solid 2px #ffffff;
        background-color: yellow;
        background-clip: padding-box;
        border-radius: 20px;
        padding: 3px;
        font-size: 9px;
        color: #ffffff;
        margin-left: -3px;
        bottom: 2px;
      }

      .kisio-network {
        display: none;
      }
    }// /kisio-public-transport

    .picto_line {
      max-height: 24px;
    }
  }// /sections, items

  .input-wrapper {
    background: #FDFDFD;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    padding: 2px 15px;
    display: flex;
    flex-direction: row;
    align-items: center; /* Add this to center the icon vertically */
    margin: 15px 0;
    /** Text **/
    color: #7A7878;
    font-family: $font-source-sans-pro;
    font-style: italic;
    font-weight: $font-weight-regular;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.333333px;

    i {
      margin-right: 10px; /* Add this to create space between the icon and input */
    }
    input {
      background: transparent;
      border: none;
      outline: none; /* Add this to remove the focus outline */
      width: 100%;
      height: 35px;
      &:hover, &:focus {
        background: transparent;
      }
    }
  }
}

/**
Bugfix simplebar css
 */
@media (min-width: 320px) {
  .shared-item, .item {
    max-width: 480px;
  }
}

@media (min-width: 600px) {
  .shared-item, .item {
    max-width: 450px;
  }

}

a {
  &:focus-visible {
    outline: auto;
  }
}